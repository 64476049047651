<template>
  <v-app >
    
    <v-main class="blue darken-4 " >
      
      <router-view class="px-0 py-0"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  created () {
    if( this.$store.state.token == ""){
      localStorage.token = ""
    }
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  font-family: 'Prompt', sans-serif;
}
</style>
