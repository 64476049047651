<template>
  <div>
    <!-- <div class="d-flex flex-column">
      <div class="switch-card">
        <p>Mode QR-Code :</p>
        <v-switch class="pl-2" v-model="switchMode" hide-details />
      </div>
    </div> -->
    <div class="d-flex flex-row justify-center py-4">
      <h2>{{ switchMode ? "Scanner" : "Manual" }}</h2>
    </div>
    <div class="d-flex flex-row justify-center">
      <v-btn width="150" class="blue darken-2 white--text" @click="changeMode">
        Change Mode
      </v-btn>
    </div>
    <v-container>
      <qrcode-stream
        v-if="switchMode"
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
        class="qrcam"
      ></qrcode-stream>
      <div v-else class="d-flex flex-column">
        <v-container style="text-align: center"><b>Input</b></v-container>
        <v-text-field
          solo
          hide-details
          autofocus
          v-on:keyup.enter="onBarcode"
          v-model="barcode_data"
        ></v-text-field>
      </div>
    </v-container>

    <v-dialog v-model="loading_dialog" persistent>
      <v-card>
        <v-container class="d-flex flex-column justify-center py-4">
          <LoadingIndicator class="align-self-center" />
          <div class="align-self-center">Loading</div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
    </v-snackbar>
    <div class="d-flex flex justify-center">
      <h2>{{ switchMode ? "กำลังสแกน..." : "" }}</h2>
    </div>
  </div>
</template>

<script>
import { getDataByBoxName } from "../modules/fetch";
import LoadingIndicator from "../components/loading_indicator.vue";
export default {
  name: "qrpage",
  props: ["data"],
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      camera: "auto",
      result: null,
      showScanConfirmation: false,
      loading_dialog: false,
      snackbar: false,
      snackbar_text: "",
      switchMode: null,
      textqrMode: "QRcode",
      textbarcodeMode: "Barcode",
      barcode_data: "",
    };
  },

  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },
    async onBarcode() {
      let boxResultData = await getDataByBoxName(this.barcode_data.toString());
      console.log(boxResultData);
      this.$store.state.boxResultData = boxResultData.data;
      if (boxResultData.status === true) {
        this.loading_dialog = false;
        this.$router.push("/details");
      } else {
        this.loading_dialog = false;
      }
    },
    async onDecode(content) {
      this.loading_dialog = true;
      this.result = content;
      let qrRead;
      let jsonResult;
      try {
        // Ty : 24 Mar 2022
        // Use this when new qrcode (only box number)
        try {
          jsonResult = JSON.parse(content);
          this.$store.state.qrdata = jsonResult;
          qrRead = jsonResult.box
        } 
        catch (err) {
          qrRead = content
          this.$store.state.qrdata = content;
        }
        console.log(qrRead);
        let boxResultData = await getDataByBoxName(qrRead);
        this.$store.state.boxResultData = boxResultData.data;
        this.pause();
        await this.timeout(500);
        this.unpause();
        this.snackbar = true;
        this.snackbar_text = "Complete";
        if (boxResultData.status === true) {
          this.loading_dialog = false;
          this.$router.push("/details");
        } else {
          this.snackbar = true;
          this.snackbar_text = "QR Code Box is Invalid";
          this.loading_dialog = false;
        }
      } catch (err) {
        console.log("error not");
        this.snackbar = true;
        this.snackbar_text = "QR Code Invalid";
        this.loading_dialog = false;
      }
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    async changeMode() {
      console.log(`mode now : ${this.switchMode}`);
      this.switchMode = !this.switchMode;
      localStorage.switchMode = this.switchMode;
      console.log(`switch mode : ${this.switchMode}`);
    },
  },
  mounted() {
    if (localStorage.switchMode) {
      let isMode = localStorage.switchMode === "true";
      this.switchMode = isMode;
      console.log(`load data from localstorage  : ${this.switchMode}`);
    } else {
      this.switchMode = true;
    }
  },
};
</script>

<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.qrcam {
  clip-path: inset(0 8% 0 8%);
}
.my-border {
  border: 1px solid #000;
}
.switch-card {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-end;
  align-items: baseline;
}
</style>
