import axios from "axios";

let config = {
    baseURL: process.env.VUE_APP_API_URI,
};
let fetch = axios.create(config);


const getDataByBoxName = async (boxName) => {
    try {
        let result = await fetch.get(`/getbyboxname/${boxName}`)
        console.log(result.data)
        return result.data
    }
    catch (err) {
        return false;
    }
}
const updateBoxStatus = async (payload) => {
    try {
        await fetch.post(`/updateboxstatus`, {

            box_name: payload.box_name,
            status: payload.status, // pick or check
            token: payload.token

        })
    } catch (err) {
        return false
    }
}

const loginWeb = async (payload) => {
    try {
        let res = await fetch.post(`/login_webapp`, {

            username: payload.username,
            password: payload.password,
            ridermobile
                : payload.ridermobile

        })

        return res.data
    } catch (err) {
        return false
    }
}

const updateTotalWeight = async (payload) => {
    try {
        let result = await fetch.post(`/update_total_weight`, {
            line_id: payload.line_id,
            total_weight: payload.total_weight
        })
        console.log('update total weight ', result.data)

    } catch (err) {
        return false;
    }

}
export { loginWeb, getDataByBoxName, updateBoxStatus, updateTotalWeight };
