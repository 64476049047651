<template>
  <div>
    <v-row>
      <v-col
        v-if="!this.$vuetify.breakpoint.mobile"
        cols="5"
        style="background-color: #1a237e; height: 100vh"
        class="d-flex justify-end pa-0"
      >
        <v-card
          width="50%"
          height="60%"
          class="align-self-center"
          color="#283593"
          style="border-radius: 0"
          elevation="5"
        >
          <v-col
            ><div class="d-flex justify-center">
              <v-img src="../assets/shining_gold.png" />
            </div>
            <div class="d-flex justify-center pt-5">
              <v-btn width="60%" outlined color="white">CONTACT US</v-btn>
            </div></v-col
          >
        </v-card></v-col
      >
      <v-col
        :cols="this.$vuetify.breakpoint.mobile ? '12' : '7'"
        style="height: 100vh"
        :class="
          this.$vuetify.breakpoint.mobile
            ? 'd-flex justify-center pa-0'
            : 'd-flex justify-start pa-0'
        "
        ><v-card
          :width="this.$vuetify.breakpoint.mobile ? '90%' : '60%'"
          :height="this.$vuetify.breakpoint.mobile ? '' : '60%'"
          class="align-self-center"
          style="border-radius: 0"
          elevation="5"
          ><v-col>
            <v-row class="d-flex justify-center ma-0 py-5"
              ><v-item-group mandatory>
                <v-container>
                  <v-row>
                    <v-col v-for="n in 2" :key="n" cols="6">
                      <v-item>
                        <v-card
                          :color="n == tab ? 'primary' : ''"
                          class="d-flex align-center"
                          dark
                          width="100"
                          height="25"
                          @click="selectType(n)"
                        >
                          <v-scroll-y-transition>
                            <v-row class="d-flex justify-center">
                              {{ n == 1 ? "User" : "Rider" }}
                            </v-row>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group></v-row
            >
            <v-row class="d-flex justify-center ma-0">
              <v-card width="50%">
                <v-col class="pa-0"
                  ><v-row class="d-flex justify-center ma-0">USERNAME</v-row>
                  <v-row class="d-flex justify-center ma-0"
                    ><v-col cols="9" class="pa-0"
                      ><v-text-field
                        v-model="username"
                        outlined
                        dense
                        label="username / phone number"
                      ></v-text-field></v-col
                  ></v-row>
                  <v-row class="d-flex justify-center ma-0">PASSWORD</v-row>
                  <v-row class="d-flex justify-center ma-0"
                    ><v-col cols="9" class="pa-0"
                      ><v-text-field
                        v-model="password"
                        outlined
                        type="password"
                        dense
                        label="password"
                      ></v-text-field></v-col
                  ></v-row>
                  <div v-if="tab == 2">
                    <v-row class="d-flex justify-center ma-0"
                      >RIDER MOBILE</v-row
                    >
                    <v-row class="d-flex justify-center ma-0"
                      ><v-col cols="9" class="pa-0"
                        ><v-text-field
                          v-model="ridermobile"
                          outlined
                          dense
                          label="mobile"
                        ></v-text-field></v-col
                    ></v-row>
                  </div>
                </v-col>
              </v-card> </v-row
          ></v-col>
          <v-row class="d-flex justify-center ma-0 py-5">
            <v-btn width="50%" color="#283593" @click="login"
              ><div style="color: white">LOGIN</div></v-btn
            >
          </v-row></v-card
        >
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        width="200"
        height="100"
        class="text-center"
      >
        <v-card>
          <v-col>
            <v-row class="ma-0">
              <h4 style="color: red">Warning</h4>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 d-flex justify-center pt-3">
              <div>LOGIN FIALED</div>
            </v-row>
            <v-row class="ma-0 d-flex justify-center">
              <div>
                <v-icon small>mdi-home</v-icon>
              </div>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { loginWeb } from "../modules/fetch";
export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      ridermobile: "",
      tab: 1,
      dialog: false,
      status: true,
    };
  },
  created() {},
  methods: {
    async login() {
      let form = {
        username: this.username,
        password: this.password,
        ridermobile: this.ridermobile == "" ? "" : this.ridermobile,
      };
      let result = await loginWeb(form);
      console.log("TOKEN = ", result);
      if (this.tab == 2 && this.ridermobile != "") {
        if (result.status) {
          localStorage.token = result.token;
          this.$store.state.token = result.token;
          this.$store.state.user = result.user;
          if (this.tab == 2) {
            this.$store.state.riderStatus = true;
            this.$store.state.rider = result.rider;
            console.log("RIDER = ", this.$store.state.rider.name);
          } else {
            this.dialog = true;
          }
          this.$router.push("/");
        } else {
          this.dialog = true;
        }
      } else if (this.tab == 1) {
        if (result.status) {
          localStorage.token = result.token;
          this.$store.state.token = result.token;
          this.$store.state.user = result.user;
          if (this.tab == 2) {
            this.$store.state.riderStatus = true;
            this.$store.state.rider = result.rider;
            console.log("RIDER = ", this.$store.state.rider.name);
          } else {
            this.dialog = true;
          }
          this.$router.push("/");
        } else {
          this.dialog = true;
        }
      } else {
        this.dialog = true;
      }
    },
    selectType(value) {
      this.tab = value;
      console.log(this.tab);
    },
    setDialog() {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      setTimeout(this.setDialog, 2000);
    },
  },
};
</script>