<template>
  <v-container>
    <v-app-bar color="blue darken-4 white--text" elevation="0">
      <v-row class="d-flex justify-space-between"
        ><div>SGJ QR</div>
        <div>
          <v-btn width="200" height="50" @click="logout">LOG OUT</v-btn>
        </div></v-row
      >
    </v-app-bar>

    <v-card class="px-8 py-6 my-4 mx-4">
      <v-card-text>
        <qrscanner />
      </v-card-text>
      <v-card-text
        v-if="this.$store.state.qrdata === null"
        class="d-flex justify-center"
      >
      </v-card-text>
      <v-card-text v-else class="d-flex justify-center">
        <h2>สำเร็จ</h2>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Qrscanner from "../components/qrscanner.vue";
export default {
  name: "Home",
  components: { Qrscanner },

  created() {
    this.$store.state.boxResultData = null;
    this.$store.state.qrdata = null;
    console.log("env mode :", process.env.NODE_ENV);
    this.getToken();
  },
  methods: {
    getToken() {
      if (this.$store.state.token == "") {
        this.$router.push("/login");
      }
    },
    logout() {
      this.$store.state.token = "";
      localStorage.token = "";
      this.$router.replace("/login");
    },
  },

  computed: {
    ...mapState({
      qrdata: (state) => state.qrdata,
      token: (state) => state.token,
    }),
  },
  data() {
    return {
      dialog: false,
      switchMode: null,
    };
  },
  mounted() {},
};
</script>
