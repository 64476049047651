<template>
  <v-container>
    <v-card class="mx-4 my-4">
      <v-card-title class="blue darken-3 white--text">
        <v-container class="d-flex justify-space-between">
          <div>
            <v-btn plain class="white--text" @click="goBack">กลับ</v-btn>
          </div>
          <div>
            {{ this.$store.state.boxResultData.line_data[0].order_id[1] }}
          </div>
          <div>
            <v-btn
              v-if="isEditing == false"
              class="white--text"
              plain
              @click="editData"
              :disabled="
                this.$store.state.boxResultData.box_data.check_status === true
              "
              ><b>แก้ไข</b></v-btn
            >
            <v-btn v-else class="white--text" plain @click="confirmEditData"
              ><b>ยืนยัน</b></v-btn
            >
          </div>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container class="px-4 py-4">
          <v-row>
            <v-col align-self="center"><b>ผู้ใช้งาน</b> : </v-col>
            <v-col>
              {{ this.$store.state.user.username }}
            </v-col>
          </v-row>
          <v-row v-if="this.$store.state.riderStatus">
            <v-col align-self="center"><b>เบอร์ Rider</b> : </v-col>
            <v-col>
              {{ this.$store.state.rider.mobile }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center"><b>Company</b> : </v-col>
            <v-col>
              {{ this.$store.state.boxResultData.order_data.company_id[1] }}
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center"><b>ชื่อลูกค้า</b> : </v-col>
            <v-col>
              {{ this.$store.state.boxResultData.line_data[0].partner_id[1] }}
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center"><b>รูท</b> : </v-col>
            <v-col>
              {{ this.$store.state.boxResultData.line_data[0].route_id[1] }}
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center">
              <b>{{ this.$store.state.boxResultData.box_data.name }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col align-self="center"
              ><b>{{
                this.$store.state.boxResultData.line_data[0].product_type ==
                "bullion"
                  ? "จำนวน ทอง :"
                  : "นน ทอง :"
              }}</b></v-col
            >
            <v-col>
              {{
                this.$store.state.boxResultData.line_data[0].product_type ==
                "bullion"
                  ? Number(sum).toLocaleString()
                  : Number(this.$store.state.boxResultData.line_data[0].product_weight).toLocaleString()
              }}
            </v-col>
          </v-row>
          <v-col
            v-if="
              this.$store.state.boxResultData.line_data[0].product_type ==
              'bullion'
            "
          >
            <div
              v-for="(i,key) in this.$store.state.boxResultData.line_data"
              :key="key"
            >
              <v-row>
                <v-col>{{ i.product_subtype[1] }}</v-col>
                <v-col>{{ i.product_qty }}</v-col>
                <v-col>{{ i.product_subtype[2] }}</v-col>
              </v-row>
            </div>
          </v-col>
          <v-row v-if="
              this.$store.state.boxResultData.line_data[0].product_type !=
              'bullion'
            ">
            <v-col align-self="center"><b>นน.กล่อง : </b></v-col>
            <v-col>
              {{ Number(this.$store.state.boxResultData.line_data[0].pack_weight).toLocaleString() }}
            </v-col>
          </v-row>

          <v-row v-if="
              this.$store.state.boxResultData.line_data[0].product_type !=
              'bullion'
            ">
            <v-col align-self="center"><b>นน.รวม :</b> </v-col>
            <v-col>
              <v-text-field v-model="totalWeight" :disabled="!isEditing" />
            </v-col>
          </v-row>

          <v-row>
            <v-col align-self="center"><b>จำนวนเงิน (Cash/Cheque) : </b></v-col>
            <v-col>
              {{ Number(this.$store.state.boxResultData.line_data[0].product_qty).toLocaleString() }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="this.$store.state.boxResultData.box_data.check_status === false"
          class="green white--text"
          width="125"
          :disabled="isEditing"
          @click="dialog = true"
          >{{
            this.$store.state.boxResultData.line_data[0].type == "out"
              ? "ตรวจสอบ"
              : "รับสินค้า"
          }}</v-btn
        >
        <v-btn
          v-else-if="
            this.$store.state.boxResultData.line_data[0].type == 'out' &&
            this.$store.state.boxResultData.box_data.pick_status != true
          "
          class="green white--text"
          width="125"
          :disabled="
            this.$store.state.boxResultData.box_data.pick_status == true
          "
          @click="pick_dialog = true"
        >
          Pick
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- History -->
    <v-container>
      <v-card>
        <v-card-title
          >หมายเลขกล่องใน
          {{ this.$store.state.boxResultData.line_data[0].order_id[1] }}
          <v-spacer />
          <!-- <v-btn class="green white--text" :disabled="allPicked">Update</v-btn> -->
        </v-card-title>
        <!-- {{this.$store.state.boxResultData.box_history }} -->
        <!-- {{ box_history  }} -->
        <v-card
          class="px-2 py-2"
          v-for="item in this.$store.state.boxResultData.box_history"
          :key="item.history"
        >
          <v-row>
            <v-col
              ><b>{{ item.name }}</b></v-col
            >
            <v-col class="green--text" v-if="item.check_status === true">{{
              type == "out" ? "Checked" : "Received"
            }}</v-col>
            <v-col class="red--text" v-else>{{
              type == "out" ? "Unchecked" : "Unreceived"
            }}</v-col>
            <div v-if="type == 'out'">
              <v-col class="green--text" v-if="item.pick_status === true"
                >Picked</v-col
              >
              <v-col class="red--text" v-else>Unpicked</v-col>
            </div>
          </v-row>
        </v-card>
      </v-card>
    </v-container>

    <!-- {{ this.$store.state.boxResultData.box_history }} -->
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>ยืนยันการตรวจสอบ</v-card-title>
        <v-card-text> บันทึกข้อมูล </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- call change status by box id -->
          <v-btn
            class="white--text"
            color="green"
            width="90"
            @click="updateBoxStatus('check')"
            >ยืนยัน</v-btn
          >
          <v-btn
            class="white--text"
            color="red"
            width="90"
            @click="dialog = false"
            >ยกเลิก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pick_dialog" persistent>
      <v-card>
        <v-card-title>Pick</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateBoxStatus('pick')">ยืนยัน</v-btn>
          <v-btn @click="pick_dialog = false">ยกเลิก</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import { mapState } from "vuex";
import { updateBoxStatus, updateTotalWeight } from "../modules/fetch";
export default {
  name: "DetailsViews",

  data() {
    return {
      isEditing: false,
      totalWeight:
        this.$store.state.boxResultData.line_data[0].pack_weight_total,
      dialog: false,
      pick_dialog: false,
      type: this.$store.state.boxResultData.line_data[0].type,
      box_history: this.$store.state.boxResultData.box_history,
      sum: 0,
      money:0,
    };
  },

  computed: {
    allPicked() {
      const isBelowThreshold = (currentValue) =>
        currentValue.pick_status == true;
      if (
        this.$store.state.boxResultData.box_history.every(isBelowThreshold) ===
        false
      ) {
        // :btn disabled props
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async updateBoxStatus(status) {
      if (this.$store.state.boxResultData.box_data.check_status === false) {
        await updateTotalWeight({
          line_id: this.$store.state.boxResultData.box_data.order_line_ids[0],
          total_weight: this.totalWeight,
        });
        await this.$store.dispatch("updateBoxResult", {
          box_name: this.$store.state.boxResultData.box_data.name,
        });
      }

      await updateBoxStatus({
        box_name: this.$store.state.boxResultData.box_data.name,
        status: status,
        token: localStorage.token,
      });

      await this.$store.dispatch("updateBoxResult", {
        box_name: this.$store.state.boxResultData.box_data.name,
      });

      this.dialog = false;
      this.pick_dialog = false;
      if (this.type == "in") {
        this.$router.go(-1);
      }
    },
    sumQty() {
      let i;
      for (i = 0; i < this.$store.state.boxResultData.line_data.length; i++) {
        if (this.$store.state.boxResultData.line_data[i].product_type == 'cash' || this.$store.state.boxResultData.line_data[i].product_type=='cheque')
          this.money = this.money + this.$store.state.boxResultData.line_data[i].product_qty;
        else
          this.sum = this.sum + this.$store.state.boxResultData.line_data[i].product_qty;
      }
    },
    editData() {
      this.isEditing = true;
    },
    confirmEditData() {
      this.isEditing = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted () {
    this.sumQty();
  },
};
</script>

<style>
</style>