/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import { getDataByBoxName } from '../modules/fetch'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    qrdata:null,
    appbar_title : null,
    boxResultData : null,
    token:"",
    rider:null,
    user:null,
    riderStatus:false
  },
  mutations: {
  },
  actions: {

    async updateBoxResult({commit}, payload) {
      let boxResultData = await getDataByBoxName(payload.box_name);
      this.state.boxResultData = boxResultData.data;
    }
  },
  modules: {
  }
})
